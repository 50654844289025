import styled from 'styled-components'
import Link from 'next/link'
import Image from 'next/legacy/image'
import Navigation from 'components/navigation'
import { SecondaryButton } from 'common/button'
import { Typography } from 'common/typography'
import Divider from 'common/divider'
import PropTypes from 'prop-types'
import useViewportHeight from 'hooks/use-viewport-height'

const ErrorStyled = styled.div`
  block-size: ${({ height }) => height};
  display: flex;
  justify-content: center;
  align-items: center;
  background: radial-gradient(50% 50% at 50% 50%, #073555 27.4%, #04001E 100%);
  .card {
    text-align: center;
  }
  @media(min-width: 1024px) {
    block-size: ${({ height }) => `calc(${height} - var(--headerBlockSize))`};
  }
`

function Error({ status, description, errorMessage }) {
  const height = useViewportHeight()
  return (
    <>
      <Navigation background="var(--neutral110)" color="var(--white)" />
      <ErrorStyled id={`error-${status}`} height={height}>
        <section className="card" data-cy="internal-error">
          {!errorMessage ? (
            <Image
              width="331"
              height="284"
              src="/images/errors/404_ilustration.png"
              alt="Personaje acuoso y luminoso con una varita magica señalando el 404"
            />
          ) : (
            <>
              <Image
                width="331"
                height="284"
                src="/images/errors/500_ilustration.png"
                alt="Personaje acuoso y luminoso con una varita magica sudando por el error"
              />
              <Typography small="headline2" color="var(--neutral10)">{errorMessage}</Typography>
            </>
          )}
          <Typography small="headline2" color="var(--neutral10)">{status}</Typography>
          <Divider marginVertical={0} marginBottom={8} color="transparent" />
          <Typography color="var(--white)">
            {description}
          </Typography>
          <Divider marginVertical={0} marginBottom={24} color="transparent" />
          <Link href="/" passHref>
            <SecondaryButton medium>
              Volver al inicio
            </SecondaryButton>
          </Link>
        </section>
      </ErrorStyled>
    </>
  )
}

Error.propTypes = {
  status: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}

export default Error
